<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Araç Ekle</span>
        </template>
        <item-form />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Kartı</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Customer from '@/views/Crm/View/Customer.vue'
import ItemForm from '@/views/Rental/VehiclePark/ItemForm.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    Customer,
    ItemForm,
  },
  created() {
    this.getCustomer()
    this.$store.commit('rentalVehiclePark/RESET_DATA_ITEM')
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.params.id)
    },
  },
}
</script>
